import React from 'react'
import { createRoot } from 'react-dom/client'
import { Environment, Network, Store, RecordSource } from 'relay-runtime'
import { lazy as loadable } from '@loadable/component'

import {
  Provider,
  tokens,
  lightComponents,
  ErrorBoundary,
  EmbedContext,
  EmbedBrowserRouter,
  wrapSuspenseComponent,
} from '@components'

import { Provider as DataProvider, createNetworkLayer } from '@data/Provider'

import { TamaguiProvider } from 'tamagui'
import tamaguiConfig from '../../config/tamagui.config'

import { HOST_NAME } from '@data/config/url'

const GuildCardEmbed = wrapSuspenseComponent(
  loadable(() => import('./Guild/GuildCardEmbed'))
)

const GuildEventLatest = wrapSuspenseComponent(
  loadable(() => import('./Guild/GuildEventLatest'))
)

const GuildEventList = wrapSuspenseComponent(
  loadable(() => import('./Guild/GuildEventList'))
)

const GuildPresentationLatest = wrapSuspenseComponent(
  loadable(() => import('./Guild/GuildPresentationLatest'))
)

const GuildPresentationList = wrapSuspenseComponent(
  loadable(() => import('./Guild/GuildPresentationList'))
)

const UserCardEmbed = wrapSuspenseComponent(
  loadable(() => import('./User/UserCardEmbed'))
)

const UserEventLatest = wrapSuspenseComponent(
  loadable(() => import('./User/UserEventLatest'))
)

const UserEventList = wrapSuspenseComponent(
  loadable(() => import('./User/UserEventList'))
)

const UserPresentationLatest = wrapSuspenseComponent(
  loadable(() => import('./User/UserPresentationLatest'))
)

const UserPresentationList = wrapSuspenseComponent(
  loadable(() => import('./User/UserPresentationList'))
)

const EventCardEmbed = wrapSuspenseComponent(
  loadable(() => import('./Event/EventCardEmbed'))
)

const EventItemEmbed = wrapSuspenseComponent(
  loadable(() => import('./Event/EventItemEmbed'))
)

const PresentationCardEmbed = wrapSuspenseComponent(
  loadable(() => import('./Presentation/PresentationCardEmbed'))
)

const PresentationItemEmbed = wrapSuspenseComponent(
  loadable(() => import('./Presentation/PresentationItemEmbed'))
)

const GRAPHQL_URL = `${HOST_NAME}/graphql`

let relayEnvironment: Environment | undefined

const renderToElement = (
  element: HTMLElement | null,
  render: () => JSX.Element
): (() => void) => {
  if (!element) {
    console.warn('Could not find HTML element to render SDK')

    return () => {}
  }

  import('./embedStyles.css')
  import('../../config/tamagui.css')

  const root = createRoot(element)

  // Cache the Relay Environment so everything in the SDK uses the same normalized cache
  if (!relayEnvironment) {
    relayEnvironment = new Environment({
      network: Network.create(
        // We may want to enable passing in these access & refresh token options in the future
        createNetworkLayer(
          undefined,
          undefined,
          async () => undefined,
          GRAPHQL_URL
        )
      ),
      store: new Store(new RecordSource(), {
        gcReleaseBufferSize: 50, // Retain the last 50 operations
        queryCacheExpirationTime: 60 * 60 * 1000, // for 1 hour
      }),
    })
  }

  if (document && document.documentElement) {
    document.documentElement.classList.add('t_light')
  }

  root.render(
    <TamaguiProvider
      config={tamaguiConfig}
      disableInjectCSS={false}
      defaultTheme='light'
    >
      <DataProvider relayEnvironment={relayEnvironment}>
        <Provider tokens={tokens} components={lightComponents}>
          <EmbedContext.Provider value={true}>
            <ErrorBoundary>
              <EmbedBrowserRouter forceExternalNavigation={true}>
                {render()}
              </EmbedBrowserRouter>
            </ErrorBoundary>
          </EmbedContext.Provider>
        </Provider>
      </DataProvider>
    </TamaguiProvider>
  )

  return () => {
    root.unmount()
  }
}

export const renderGuildCard = (
  element: HTMLElement,
  guildSlug: string,
  options: { expand?: boolean } = {}
) => {
  return renderToElement(element, () => (
    <GuildCardEmbed {...options} slug={guildSlug} />
  ))
}

export const renderGuildEventLatest = (
  element: HTMLElement,
  guildSlug: string,
  options: {
    showOrganizer?: boolean
    showPresentationsOrAttendees?: boolean
    expand?: boolean
  } = {}
) => {
  return renderToElement(element, () => (
    <GuildEventLatest {...options} slug={guildSlug} />
  ))
}

export const renderGuildEventList = (
  element: HTMLElement,
  guildSlug: string,
  timeSelection: 'UPCOMING' | 'PAST',
  options: {
    itemGap?: number
    numberToDisplay?: number
    expand?: boolean
    itemsDisplayed?: (itemsDisplayed: number) => unknown
    hasMore?: (hasMore: boolean) => unknown
  } = {}
) => {
  return renderToElement(element, () => (
    <GuildEventList
      {...options}
      slug={guildSlug}
      timeSelection={timeSelection}
    />
  ))
}

export const renderGuildPresentationLatest = (
  element: HTMLElement,
  guildSlug: string,
  options: {
    showOrganizer?: boolean
    showPresentationsOrAttendees?: boolean
    expand?: boolean
  } = {}
) => {
  return renderToElement(element, () => (
    <GuildPresentationLatest {...options} slug={guildSlug} />
  ))
}

export const renderGuildPresentationList = (
  element: HTMLElement,
  guildSlug: string,
  timeSelection: 'UPCOMING' | 'OTHER',
  options: {
    itemGap?: number
    numberToDisplay?: number
    expand?: boolean
    itemsDisplayed?: (itemsDisplayed: number) => unknown
    hasMore?: (hasMore: boolean) => unknown
  } = {}
) => {
  return renderToElement(element, () => (
    <GuildPresentationList
      {...options}
      slug={guildSlug}
      timeSelection={timeSelection}
    />
  ))
}

export const renderUserCard = (
  element: HTMLElement,
  userSlug: string,
  options: { expand?: boolean } = {}
) => {
  return renderToElement(element, () => (
    <UserCardEmbed {...options} slug={userSlug} />
  ))
}

export const renderUserEventLatest = (
  element: HTMLElement,
  userSlug: string,
  options: {
    showOrganizer?: boolean
    showPresentationsOrAttendees?: boolean
    expand?: boolean
  } = {}
) => {
  return renderToElement(element, () => (
    <UserEventLatest {...options} slug={userSlug} />
  ))
}

export const renderUserEventList = (
  element: HTMLElement,
  userSlug: string,
  timeSelection: 'UPCOMING' | 'PAST',
  options: {
    itemGap?: number
    numberToDisplay?: number
    expand?: boolean
    itemsDisplayed?: (itemsDisplayed: number) => unknown
    hasMore?: (hasMore: boolean) => unknown
  } = {}
) => {
  return renderToElement(element, () => (
    <UserEventList {...options} slug={userSlug} timeSelection={timeSelection} />
  ))
}

export const renderUserPresentationLatest = (
  element: HTMLElement,
  userSlug: string,
  options: {
    showOrganizer?: boolean
    showPresentationsOrAttendees?: boolean
    expand?: boolean
  } = {}
) => {
  return renderToElement(element, () => (
    <UserPresentationLatest {...options} slug={userSlug} />
  ))
}

export const renderUserPresentationList = (
  element: HTMLElement,
  userSlug: string,
  timeSelection: 'UPCOMING' | 'OTHER',
  options: {
    itemGap?: number
    numberToDisplay?: number
    expand?: boolean
    itemsDisplayed?: (itemsDisplayed: number) => unknown
    hasMore?: (hasMore: boolean) => unknown
  } = {}
) => {
  return renderToElement(element, () => (
    <UserPresentationList
      {...options}
      slug={userSlug}
      timeSelection={timeSelection}
    />
  ))
}

export const renderEventCard = (
  element: HTMLElement,
  eventSlug: string,
  options: { expand?: boolean } = {}
) => {
  return renderToElement(element, () => (
    <EventCardEmbed {...options} slug={eventSlug} />
  ))
}

export const renderEventItem = (
  element: HTMLElement,
  eventSlug: string,
  options: { expand?: boolean } = {}
) => {
  return renderToElement(element, () => (
    <EventItemEmbed {...options} slug={eventSlug} />
  ))
}

export const renderPresentationCard = (
  element: HTMLElement,
  presentationSlug: string,
  options: { expand?: boolean } = {}
) => {
  return renderToElement(element, () => (
    <PresentationCardEmbed {...options} slug={presentationSlug} />
  ))
}

export const renderPresentationItem = (
  element: HTMLElement,
  presentationSlug: string,
  options: { expand?: boolean } = {}
) => {
  return renderToElement(element, () => (
    <PresentationItemEmbed {...options} slug={presentationSlug} />
  ))
}

export default {
  renderGuildCard,
  renderGuildEventLatest,
  renderGuildEventList,
  renderGuildPresentationLatest,
  renderGuildPresentationList,
  renderUserCard,
  renderUserEventLatest,
  renderUserEventList,
  renderUserPresentationLatest,
  renderUserPresentationList,
  renderEventCard,
  renderEventItem,
  renderPresentationCard,
  renderPresentationItem,
}
